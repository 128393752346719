var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"_pB6T7DJQekjPaEj_BjV0"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SAMPLE_RATE = process.env.NEXT_PUBLIC_SENTRY_SAMPLE_RATE || 1.0;
const isProduction = process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';

Sentry.init({
  dsn: isProduction
    ? SENTRY_DSN || 'https://dfccd7e906ce446888c4c91a66922171@o417769.ingest.sentry.io/6167411'
    : undefined,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: Number(SAMPLE_RATE),
  environment: process.env.NEXT_PUBLIC_ENVIRONMENT || 'dev',
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0,
  ignoreErrors: [/.*replaceAll.*/]
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
